import React, { FunctionComponent } from 'react'
import { graphql, PageProps } from 'gatsby'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { css } from 'styled-components'

import Page from '~/components/Page'
import Link from '~/components/Link'

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...LayoutData
		# ...FooterData
	}
`

type Props = {
	data: {}
} & PageProps

const NotFoundPage: FunctionComponent<Props> = ({ location, data, pageContext }) => (
	<Page>
		<Box display="flex" alignItems="center" justifyContent="center" minHeight="500px">
			<Container
				css={css`
					text-align: center;
				`}
			>
				<Typography variant="h1" gutterBottom>
					404: Page not found.
				</Typography>
				<Typography variant="body1">
					Sorry, that page can't be found.
					<br />
					<br />
					<Link to="/">Please go back</Link>
				</Typography>
			</Container>
		</Box>
	</Page>
)

export default NotFoundPage
